import { version } from './version';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@scottwittrock/garage/loader';

console.log({version});

if (environment.production) {
  Sentry.init({
    dsn: 'https://14ffda418cad4bb880737a032ff4365c@o521850.ingest.sentry.io/5632450',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', 'https://dashboard.mymealplanner.app', 'https://create.mymealplanner.app'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    environment: 'production',
    release: `my-meal-planner-dashboard@${version}`,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

defineCustomElements(window);
