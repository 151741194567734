import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from '../firebase/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class MainAuthGuardGuard implements CanActivate {

  constructor(
    private router: Router, 
    private auth: AngularFireAuth, 
    private userService: UserService
  ){

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log(route.data);
    return this.authorize(state.url);
  }

  authorize(attemptedPath: String): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const authUser = await this.auth.currentUser;
      if(authUser) {
        const user = await this.userService.get(authUser.uid);
        if(user.adminPermissions.dashboard_admin){
          this.router.navigate(['admin']);
          resolve(false);
        }
        if(user.adminPermissions.dashboard_creator) {
          this.router.navigate(['dashboard']);
          resolve(false);
        }
        resolve(true);
      } else {
        // console.log(attemptedPath);
        // this.router.navigate(['auth']);
        resolve(false);
      }
      resolve(true);
    })
  }

}
