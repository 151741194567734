

<div class="container">
  <g-img [src]="'assets/imgs/chef.svg'" [backgroundSize]="'contain'">

  </g-img>
  <div class="welcome">
    <h1>Thank you for signing up.</h1>
    <p>If you really want in, give us a call so we can see if you are right fit for our beta.</p>
    <g-button
      (gClick)="nav()"
    >
      Schedule Chat
    </g-button>
    <g-button
      (gClick)="logout()"
    >
      Signout
    </g-button>
  </div>
</div>
