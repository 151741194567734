import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular";
@Component({
  selector: 'app-beta',
  templateUrl: './beta.component.html',
  styleUrls: ['./beta.component.scss']
})
@Sentry.TraceClassDecorator()
export class BetaComponent implements OnInit {

  constructor(
    private afa: AngularFireAuth,
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  nav(){
    window.open('https://calendly.com/scottwittrock/my-meal-planner-information-session', "_blank")
  }

  logout(){
    this.afa.signOut().then(() => {
      this.router.navigate(['/auth'])
    });
  }
}
