import { Injectable } from '@angular/core';
import { AngularFirestore, QuerySnapshot, DocumentSnapshot } from '@angular/fire/firestore';
import { User } from 'src/models/user';

export interface UsersQueryOptions {
  start?: DocumentSnapshot<User> | undefined, 
  limit?: number | undefined, 
  sort?: 'email' | 'created' | undefined,
  sortDirection?: 'asc' | 'desc'
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private afs: AngularFirestore
  ) { }


  query(options: UsersQueryOptions): Promise<User[]> {
    const local = {
      start: options.start ? options.start : null,
      limit: options.limit ? options.limit : 10,
      sort: options.sort ? options.sort: 'email',
      sortDirection: options.sortDirection ? options.sortDirection: 'asc' 
    }
    return new Promise((resolve, reject) => {
      this.afs.collection('users', ref => ref
        .limit(local.limit)
        .orderBy(local.sort, 'asc')
        .startAt(local.start)
      ).get().toPromise().then((snapshots: any) => {
        const users: User[] = snapshots.docs.map((snapshot: any)=> {
          return new User(
            snapshot.id,
            snapshot.data().firstName || null,
            snapshot.data().lastName || null,
            snapshot.data().email,
            snapshot.data().created.toDate(),
            snapshot.data().updated.toDate(),
            snapshot.data().organization || null,
            snapshot.data().profile_url || null,
            snapshot.data().adminPermissions,
            snapshot.data().stripe_customer_id
          )
        })
        resolve(users);
      }).catch((err)=> {
        reject(err);
      });
    })
  }

}
