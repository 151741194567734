import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from 'src/models/user';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ShortLink } from 'src/models/shortLink';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private privateCurrentUser?: User;

  constructor(
    private afs: AngularFirestore,
    private afa: AngularFireAuth,
    private aff: AngularFireFunctions
  ) { }

  create(email: string, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const callable = this.aff.httpsCallable('getCustomerId');
      callable({ email, id}).toPromise().then((result) => {
        const user: User = {
          firstName: null,
          lastName: null,
          email,
          created: new Date(),
          updated: new Date(),
          organization: null,
          profile_url: null,
          adminPermissions: {
            dashboard_admin: false,
            dashboard_creator: false,
            dashboard_creator_active: false,
          },
          stripe_customer_id: result
        }
        this.afs.collection('users').doc(id).set(user).then(() => {
          const shortLink: ShortLink = {
            username: '',
            creator_id: id,
            headline: '',
            subtitle: '',
            image_url: '',
          }
          this.afs.collection('short_links').doc(id).set(shortLink).then(() => {
            resolve();
          }).catch(() => {
            reject()
          })
        });
      }, (err) => {
        reject(err.message);
      });
    });
  }

  get(id: string): Promise<User> {
    return new Promise((resolve, reject) => {
      const getUser = async (id: string): Promise<void> => {
        this.afs.collection('users').doc(id).get().toPromise().then((snapshot: any) =>{
          if(snapshot.exists) {
            resolve(new User (
             snapshot.id,
             snapshot.data().firstName || null,
             snapshot.data().lastName || null,
             snapshot.data().email,
             snapshot.data().created.toDate(),
             snapshot.data().updated.toDate(),
             snapshot.data().organization || null,
             snapshot.data().profile_url || null,
             snapshot.data().adminPermissions,
             snapshot.data().stripe_customer_id
           ));
          } else {
            console.log('Wait .5 seconds to try to get user again ' + id);
            setTimeout(() => {
              console.log('Retrying to get user');
               getUser(id);
            }, 500);
          }
       }).catch((err) => {
          reject(err);
       });
      }
      getUser(id);
      
    });
  }

  update(user: User): Promise<void> {
    const updateUser: User = {
      ...user,
      updated: new Date()
    };
    if (updateUser.id) {
      delete updateUser.id;
    }
    return this.afs.collection('users').doc(user.id).update(updateUser);
  }

  currentUser(): Promise<User>{
    if (this.privateCurrentUser) {
      return new Promise((resolve, reject) => {
        resolve(this.privateCurrentUser);
      });
    } else {
      return new Promise((resolve, reject) => {
        this.afa.currentUser.then((user)=> {
          if(user){
            this.get(user.uid).then((user: User)=> {
              resolve(user);
            });
          } else {
            resolve()
          }
        })
      })
    }
  }
}
