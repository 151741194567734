import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ADMIN_PERMISSIONS_STRINGS } from 'src/models/adminPermissions';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from '../firebase/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {  
  constructor(
    private router: Router, 
    private auth: AngularFireAuth, 
    private userService: UserService
  ){

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const permissions = route.data.permissions as ADMIN_PERMISSIONS_STRINGS[];
    return this.authorize(permissions);
  }


  authorize(permissions: ADMIN_PERMISSIONS_STRINGS[]): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const authUser = await this.auth.currentUser;
      if(authUser) {
        const user = await this.userService.get(authUser.uid);
        let allowed = false;
        permissions.forEach((permission) => {
          if (user.adminPermissions[permission] === true) {
            allowed = true;
          }
        })
        if(allowed) {
          resolve(true)
        }else {
          //TODO: [MEAL-187] why does the web component not fully load here
          this.router.navigate(['auth']);
          resolve(false)
        }
      } else {
        // console.log(attemptedPath);
        this.router.navigate(['auth']);
        resolve(false);
      }
      resolve(true);
    })
  }
  
}
