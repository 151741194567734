import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo, hasCustomClaim } from '@angular/fire/auth-guard';
import { ADMIN_PERMISSIONS_STRINGS } from 'src/models/adminPermissions';
import { NotFoundComponent } from './not-found/not-found.component';
import { MainAuthGuardGuard } from './guards/main-auth-guard.guard';
import { BetaComponent } from './beta/beta.component';
import { PermissionsGuard } from './guards/permissions-guard.guard';



const redirectUnauthorizedToLogin = (router: any) => redirectUnauthorizedTo(['/auth']);
const redirectSignedIn = () => redirectLoggedInTo(['']);

const creator: ADMIN_PERMISSIONS_STRINGS = 'dashboard_creator';
const admin: ADMIN_PERMISSIONS_STRINGS = 'dashboard_admin';

const routes: Routes = [
  {
    path: '',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: '',
        canActivate: [MainAuthGuardGuard],
        component: BetaComponent
      },
      {
        path: 'dashboard',
        canActivate: [PermissionsGuard],
        data: {
          permissions: [creator]
        },
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'beta',
        component: BetaComponent
      },
      {
        path: 'admin',
        canActivate: [PermissionsGuard],
        data: {
          permissions: [admin]
        },
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
      }]
  },
  {
    path: 'auth',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectSignedIn },
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: { title: 'App Router' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
