import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FirebaseModule } from './firebase/firebase.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { MainAuthGuardGuard } from './guards/main-auth-guard.guard';
import { BetaComponent } from './beta/beta.component';
import { PermissionsGuard } from './guards/permissions-guard.guard';
import { AuthService, authFactory } from './firebase/auth/auth.service';
import { PipesModule } from './pipes/pipes.module';
import { HttpClientModule } from '@angular/common/http';

import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    BetaComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FirebaseModule,
    PipesModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    MainAuthGuardGuard,
    PermissionsGuard,
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: authFactory,
      deps: [AuthService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
