import { Component } from '@angular/core';
import * as Sentry from "@sentry/angular";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
@Sentry.TraceClassDecorator()
export class AppComponent {
  title = 'dashboard';

}
