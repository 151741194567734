export const environment = {
  production: true,
  functions: 'https://us-central1-meal-planner-d5fc4.cloudfunctions.net',
  useEmulators: false,
  creator_base_url: 'https://plan.mymealplanner.app/c/',
  storage: {
    bucket: 'meal-planner-d5fc4-production'
  },
  stripe: {
    price_creator_15: 'price_1HvSXiGsTBsHAXyohHw2Pdv7'
  },
  firebase: {
    apiKey: "AIzaSyDX_IvZF0T31UXT4RcKXhNi_7FThMvGlKo",
    authDomain: "meal-planner-d5fc4.firebaseapp.com",
    databaseURL: "https://meal-planner-d5fc4.firebaseio.com",
    projectId: "meal-planner-d5fc4",
    storageBucket: "meal-planner-d5fc4.appspot.com",
    messagingSenderId: "735792104989",
    // Dashboard app id
    appId: "1:735792104989:web:fa4c652a711b0368fa68f4"
  }
};
